import React, { useState, useEffect } from 'react';

import Footer from "../components/Footer";
import Header from "../components/Header";
import "../assets/css/Aboutus.css";
var Loader = require('react-loader')



const AboutUs = () => {
    const [loaded, setLoaded] = useState(false);

 
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
          }, 300);
      }, []);
  
  return (
    <>
        <Loader loaded={loaded}>
      <Header />
      <div className="full">
        <div className="section section-custom-13">
          <div className="containers">
            <div className="headline">
              <h1 className="name text-center">About</h1>
              <p className="headline_summary">
                Shree Raghavendarar Travels - Personalized Experiences, Proven Legacy (18 Years), Customer Service(24/7), Tasty Food.
              </p>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className="row mission">
              <div className="col  mission2">
                <h4 className="off-head text-center"> Why Us</h4>
                <h6 className="text-center "> Welcome to Shree Raghavendarar Travels,</h6>
                <p>
                Embark on a spiritually enriching journey with us, backed by an impressive 18-year legacy of commitment to excellence. Enjoy not just spiritual exploration but also delightful culinary adventures with our tasty and nourishing meals. Our reliable 24/7 customer service ensures your peace of mind, providing support at every stage. Most importantly, we specialize in crafting personalized experiences, recognizing that spirituality is a unique and individual journey. Choose us for a seamless blend of a proven legacy, delectable culinary explorations, dependable customer service, and a dedication to delivering personalized spiritual journeys, marking the beginning of your meaningful pilgrimage.{" "}
                </p>
                
              </div>
            </div>

            <div className="row mission mt-5">
              <div className="col  mission2">
                <h4 className="off-head text-center"> What We Offer</h4>
                <div className="row mt-5">
                    <div className="col-md-3 col-sm-12 text-center">
                        <img src="images/tour-managers.svg" className="offer-icons"/>
                        <h4>Tour Managers</h4>
                        <p className="offertext">
                        350+ Tour Managers celebrating life with thousands of travellers everyday
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-12 text-center">
                      <img src="images/visa.svg" className="offer-icons"/>
                      <h4>Air & VISA</h4>
                      <p className="offertext">
                      With a dedicated team, all your Air & VISA needs are taken care of
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-12 text-center">
                      <img src="images/operations.svg" className="offer-icons"/>
                      <h4>3000+ Departures</h4>
                      <p className="offertext">
                      With multiple tours and departure dates, you pick the tour that suits your travel date the most
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-12 text-center">
                      <img src="images/support.svg" className="offer-icons"/>
                      <h4>24x7 Connect</h4>
                      <p className="offertext">
                      Our Guest Relations team in available for you 24x7 all throughout
                      </p>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section3 master">
          <div className="row images">
            <div className="col mass">
              <img
                src="images/t3.jpg"
                alt=""
                className="tag-img"
              />
            </div>
            <div className="col mass ">
              <img
                src="images/t2.jpg"
                alt=""
                className="tag-img"
              />
            </div>
            <div className="col mass">
              <img
                src="images/t1.jpg"
                alt=""
                className="tag-img"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
      </Loader>
    </>
  );
};

export default AboutUs;
