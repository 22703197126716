// Routes.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Detail from './pages/Detail';
import Lists from './pages/List';
import Terms from './pages/Terms-Conditions';
import Privacy from './pages/Privacy-Policy';
import AboutUs from './pages/About-Us';
import ContactUs from './pages/Contact';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/detail" element={<Detail />} />

        {/* <Route path="/detail/:id" element={<Detail />} /> */}
        <Route path="/list" element={<Lists />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
