import React from 'react';
import '../assets/css/Tourdream.css';
const Tourdreams = () => {
  return (
    <div className='DreamTour container'>
    
        <div className="tour-dreams">
          <h1 className="normal-text">
            We bring to life your <span className='colGreen'> Tour Dreams <p className='LineSVG'> 
            <img src="images/vector.png" /> </p> </span>
          </h1>
        </div>

              <div className='Tourdreams'>
                <div className="row">
                  <div className="col-lg-3 col-sm-12 col-md-4">
                  <div className='ImageRank'>
      <div className='image-Container'>
        <img className="ImageFill RotateOnHover" src="images/Greencircle.png" alt="Green circle" />
        <img className="ImageFill FillCircle" src="images/fillcircle.png" alt="circle" />
      </div>
      <div className='Rank'>
        <p className='rankName'>1245</p>
      </div>
    </div>
    <div className='ImageDescription'>
      <p>Travel pack Completed In Last 5 Years</p>
    </div>
                  </div>
                  <div  className="col-lg-3 col-sm-12 col-md-4">
                  <div className='ImageRank'>
      <div className='image-Container'>
        <img className="ImageFill RotateOnHover" src="images/Greencircle.png" alt="Green circle" />
        <img className="ImageFill FillCircle" src="images/fillcircle.png" alt="circle" />
      </div>
      <div className='Rank'>
        <p className='rankName'>7000+</p>
      </div>
    </div>
    <div className='ImageDescription'>
      <p>Worldwide Premium Members</p>
    </div>
                  </div>
                  <div  className="col-lg-3 col-sm-12 col-md-4">
                  <div className='ImageRank'>
      <div className='image-Container'>
        <img className="ImageFill RotateOnHover" src="images/Greencircle.png" alt="Green circle" />
        <img className="ImageFill FillCircle" src="images/fillcircle.png" alt="circle" />
      </div>
      <div className='Rank'>
        <p className='rankName'>500+</p>
      </div>
    </div>
    <div className='ImageDescription'>
      <p>Biggest Collection of Popular Indian Temples</p>
    </div>
                  </div>
                  <div  className="col-lg-3 col-sm-12 col-md-4">
                  <div className='ImageRank'>
      <div className='image-Container'>
        <img className="ImageFill RotateOnHover" src="images/Greencircle.png" alt="Green circle" />
        <img className="ImageFill FillCircle" src="images/fillcircle.png" alt="circle" />
      </div>
      <div className='Rank'>
        <p className='rankName'>1705</p>
      </div>
    </div>
    <div className='ImageDescription'>
      <p>Local & International Client Tie-ups</p>
    </div>
                  </div>
                  
                </div>
              </div>
      
    </div>
  );
};

export default Tourdreams;