import "@fontsource/prompt";
import "@fontsource/prompt/500.css";
import "@fontsource/prompt/400-italic.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Import Bootstrap JS bundle
import React from 'react';
import './App.css';
import Routes from './Routes';

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
