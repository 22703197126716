// Home.js
import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import toursData from '../Torudetails.json';

import Lists from '../components/list';

const List = () => {
 
  return (
    <>
      <Lists />
      
    </>
  );
};

export default List;
