import React, { useState, useEffect } from "react";
import ourGallery from "../OurGallery.json";
import "../assets/css/style1.css";
import Loader from "react-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Gallery = () => {
  const [loaded, setLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [showAll, setShowAll] = useState(false); // State to handle "View More" and "Show Less" functionality

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (isModalOpen) {
        e.preventDefault();
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  const images = ourGallery.images;
  const [selectedFilter, setSelectedFilter] = useState("Kaasi");

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredImages =
    selectedFilter === "all"
      ? images
      : images.filter((image) => image.category === selectedFilter);

  const imagesToShow = showAll ? filteredImages : filteredImages.slice(0, 12); // Slice the images based on showAll state

  const openModel = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const showImage = (cli, index) => {
    setSelectedImageURL(cli);
    setSelectedImageIndex(index);
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleLeftArrowClick = () => {
    const container = document.getElementById("gallery-images-container");
    if (container) {
      const newPosition = scrollPosition - 100;
      container.scrollLeft = newPosition >= 0 ? newPosition : 0;
      setScrollPosition(newPosition >= 0 ? newPosition : 0);
    }
  };

  const handleRightArrowClick = () => {
    const container = document.getElementById("gallery-images-container");
    if (container) {
      const newPosition = scrollPosition + 100;
      container.scrollLeft = newPosition;
      setScrollPosition(newPosition);
    }
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="our-gallery-section text-center">
      <div className={`container `}>
        <div className="row">
          <div className="tour-dreams pt-5">
            <h1 className="normal-text1">
              Our{" "}
              <span className="colGreen1">
                &nbsp; Gallery{" "}
                <p className="LineSVG1">
                  {" "}
                  <img src="images/vector.png" />{" "}
                </p>{" "}
              </span>
            </h1>
          </div>
        </div>
        <div className={`row mx-md-2 our-gallery-images ${isModalOpen ? "blur-background" : ""}`} >
          {imagesToShow.map((image, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-4 col-sm-6 img-container1 p-0 px-md-2  m-0"
              onClick={() => showImage(image.src, index)}
            >
              <img
                src={image.src}
                alt={image.alt}
                className={`img-fluid1 col-lg-11 ${selectedImageIndex === index ? "selected-image" : ""}`}
              />
              <div className="overlay">
                <div className="text MidText"></div>
                <div className="text1">View Gallery</div>
                <div className="ExploreImg">
                  <img className="Explore" src="images/Cardlable.svg" />
                </div>
              </div>
              <p className="text-center pt-2">{image.description}</p>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <div className="gallry-view">
            <button className="" onClick={toggleShowAll}>
              {showAll ? "Show Less" : "View More"}
            </button>
            </div>
          </div>
        </div>

        <Loader loaded={loaded}>
          <div
            id="model"
            className="modal"
            style={{ display: isModalOpen ? "block" : "none" }}
          >
            <span className="close" onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="currentColor"
                className="bi bi-x-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </span>
            <div className="gallery-container">
              <div className="selected-image">
                <div className="selectedimg">
                  {selectedImageIndex !== null && filteredImages[selectedImageIndex] && (
                    <LazyLoadImage
                      id="selected-image"
                      src={filteredImages[selectedImageIndex].cli}
                      alt="Selected Image"
                    />
                  )}
                </div>
              </div>
              <div className="buttons">
                <button onClick={handleLeftArrowClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-caret-left-square-fill" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm10.5 10V4a.5.5 0 0 0-.832-.374l-4.5 4a.5.5 0 0 0 0 .748l4.5 4A.5.5 0 0 0 10.5 12"/>
                  </svg>
                </button>
                <button onClick={handleRightArrowClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-caret-right-square-fill" viewBox="0 0 16 16">
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4z"/>
                  </svg>
                </button>
              </div>

              <div id="gallery-images-container" className="gallery-images-1">
                {filteredImages.map((image, index) => (
                  <LazyLoadImage
                    key={index}
                    className={`rounded-start ${selectedImageIndex === index ? "selected-image-border" : ""}`}
                    src={image.cli}
                    alt={image.alt}
                    onClick={() => showImage(image.cli, index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default Gallery;
