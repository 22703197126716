import "../assets/css/style.css";
import "../assets/css/responsive.css"; 
import "../assets/css/responsive1.css"; 


const Contact = () => {
 

  return (
    <>
      <div className="col-12 text-center contact-section">
        <div className="">
           <h2>For more details follow our whatsapp business account</h2>
           <div className="whatsapp-section">
           <a href="https://api.whatsapp.com/send/?phone=9585345454&text=hi&type=phone_number&app_absent=0"><button>Get More Details</button></a>
           </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
