// Lists.jscalendar-range 1.png
import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
import RelatedTour from '../components/RelatedTour';
import Header from '../components/Header';
import Footer from '../components/Footer';
var Loader = require('react-loader') 

const Lists = () => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const detail=(data)=>{
    console.log(data);
    navigate('/detail',{state:{id:data}});
  }
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const tourData = require('../Torudetails.json');
    
      const tours = tourData.tours;
      const sortedTours = tours;
      const limitedData = sortedTours.slice(0, 13);
      limitedData.sort((a, b) => {
      return new Date(a.tourDetails.dates.startDate) - new Date(b.tourDetails.dates.startDate);
      })
    if (limitedData) {
      setTimeout(() => {
          setTours(limitedData);
          setLoaded(true);
        }, 300);
    }
    
    
  }, []);

  return (
    <>
    <Loader loaded={loaded}>
    <Header />
      <section id="main-sec">
      <div className="h">
        <div className="package-head">
           <h2> Upcoming Trips </h2>
           <img src="images/Package-icon.png" alt=''/>
        </div>
        </div>

        <section>
          <div className="wrapper">

          <div className="left-section">
            <div>
                <p class="hd-1">Most Visited Places</p>
            </div>
          
           
        <div id="fst">
           <table  class="able">
               
           {tours.map((tour) => (
                      <tr key={tour.id}>
                        <td  onClick={()=>{detail(tour.id)}}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-circle-fill" id="circle" viewBox="0 0 16 16">
                            <circle cx="8" cy="8" r="8" />
                          </svg>{' '}
                          {tour.tourDetails.tourName}
                        </td>
                      </tr>
                    ))}

          </table>
        </div>
      </div>
            <div className="right-section">
              <div className="Tour-package">
                <p className="Packages">
                  Upcoming Trips<span className="">({tours.length})</span> 
                </p>
              </div>

              {tours.map((tour) => (
                <div key={tour.id}  className="main-sec" onClick={()=>{detail(tour.id)}} style={{cursor:'pointer'}}>
                  <div className="img-left p-2">
                    <img className="thumbnail" src={tour.tourDetails.thumbnail} alt="Tour Thumbnail" />
                  </div>
                  <div className="content-right">
                    <h2 id="tourName" className='pt-3'>{tour.tourDetails.tourName}</h2>
                    <p className='shortDec'>{tour.tourDetails.shortDescription}</p>
                      <div className="txt-5">
                        <b>
                          <span>
                            <img src="images/calendar-range 1.png" alt="Calendar" />
                          </span>{' '}
                          {tour.tourDetails.duration}
                        </b>
                        <span className="days" id="dayDuration">
                          ({tour.tourDetails.dayDuration} days)
                        </span>
                      </div>
                    <div className='destination'>
                      <span>
                        <img src="images/location-1.png" alt="Location" />
                      </span>{' '}
                      {tour.tourDetails.destination}
                    </div>
                  <div className='col-12'>
                  <div className='row'>

                    <div className='col-md-6 col-sm-12'>
                      <div className="persons pt-3">
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg>
                          {tour.tourDetails.price.amount.toLocaleString('en-US')}/&nbsp;
                        </span>
                         1 Person
                      </div>
                    </div>

                    <div className='col-md-6 col-sm-12 bottom-button'>  
                      <button onClick={()=>{detail(tour.id)}} className='detail-button'>View Details</button>
                    </div>

                  </div>
                  </div>
                 </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>
      <RelatedTour />
      <Footer />
      </Loader>
    </>
  );
};

export default Lists;
