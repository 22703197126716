import React, { useState, useEffect } from 'react';
import jsonData from '../Torudetails.json';
import '../assets/css/RelatedTour.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';
const RelatedTour = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const tours = jsonData.tours;
    const sortedTours = tours.sort((a, b) => b.id - a.id);
    const limitedData = sortedTours.slice(0, 4);
    limitedData.sort((a, b) => {
      return new Date(a.tourDetails.dates.startDate) - new Date(b.tourDetails.dates.startDate);
      })

    setData(limitedData);
  }, []);

   const navigate = useNavigate();

  const detail=(data)=>{
    navigate('/detail',{state:{id:data}});
  }

  return (
    <div className="RelatedTour container">
      <h4 className="Title">Related Tours</h4>
      <div className="TourImages">
      <div className="row">
        {data.map((tour, index) => (
          <div key={index} className="col-lg-3 col-sm-12 col-md-4">
          <div className="img-container" onClick={()=>{detail(tour.id)}}>
            <img src={tour.tourDetails.thumbnail} alt={`Image ${index + 1}`} className="image" />
            <div class="overlay">
              <div class="text1">Explore</div>
              <div className='ExploreImg'><img class="Explore" src="images/Cardlable.svg" /></div>
            </div>
            <p className="ImageTitle">{tour.tourDetails.tourName}</p>
            <div className="rating">
              {[...Array(tour.tourDetails.rating)].map((_, index) => (
                <FontAwesomeIcon key={index} icon={faStar} className="star" style={{ color: '386B33'}} />
              ))}
              {[...Array(5 - tour.tourDetails.rating)].map((_, index) => (
                <FontAwesomeIcon key={tour.tourDetails.rating + index} icon={faStar}  className="star" style={{ color: '#FFF'}} />
              ))}
            </div>
            
          </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default RelatedTour;
