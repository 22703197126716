import background from "../assets/img/upcoming-bg.png";
import "../assets/css/Upcomingtrips.css"

const UpcomingTrip = () => {
  const containerStyle = {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    paddingTop:'5%'
  };

   const imageUrls = [
   
    'images/union1.svg',
    'images/union1.svg',
    'images/union1.svg',
    'images/union1.svg',
  ];
  const imageUrls1 = [
   
    'images/ramar.png',
    'images/ramar.png',
    'images/ramar.png',
    'images/ramar.png',
  ];

  const imageTexts = [
    '08 Sep',
    '18 Sep',
    '01 Oct',
    '15 Oct',
  ];
  const imageTexts1 = [
    '12 Sep',
    '27 Sep',
    '09 Oct',
    '20 Oct',
  ];


  return (
    <>
      
      <div className="col-12 text-center upcoming-section">
         <h2 className="district"><span><img src="images/geo-fill 2.png"/></span>Our Next Trips</h2>
         <p className="district-para">Join With Us</p>


      <div className="image-container1 container">
      <div className="image-row1 container">
        {imageUrls.map((url, index) => (
          <div key={index}>

          <div className={`text-wrapper1 ${index % 2 === 0 ? 'odd_text' : 'even_text'}`}>
              <p className="date1">{imageTexts[index]}</p>
              <p className="date1">to</p>
              <p className="date1">{imageTexts1[index]}</p>
          </div>

          <div className={`image-wrapper1 ${index % 2 === 0 ? 'even-index' : 'odd'}`}>
            <img src={imageUrls[index]} alt={`Image ${index + 1}`} className="img1"/>
            <img src={imageUrls1[index]} alt={`Image ${index + 1}`} className={`overlay-image ${index % 2 === 0 ? 'even-index1' : 'odd-index1'}`} />
          </div>
          </div>
          
        ))}
      </div>


      
     <div className="container">
     <div className="container1">
     <div className="Location">
      <img src="images/location.svg"/>
      </div>
      <img src="images/GreenLine.png" className="horizontal-line" />
      <div className="Location">
      <img src="images/greenlocation.svg"/>
      </div>
    </div>
    
    <div className="container2">
     <div className="Location1">
      <p className="date">Sep 2024</p>
      </div>
      <div className="Location2">
      <p className="date">Oct 2024</p>
      </div>
    </div>
    </div>


      <div className="image-row2 container">
        {imageUrls.map((url, index) => (
        <div key={index}>
          <div className={`text-wrapper ${index % 2 === 0 ? 'even_text' : 'odd_text'}`}>
              <p className="date1">{imageTexts[index]}</p>
              <p className="date1">to</p>
              <p className="date1">{imageTexts1[index]}</p>
          </div>

          <div className={`image-wrapper2 ${index % 2 === 0 ? 'even' : 'odd-index'}`}>
            <img src={imageUrls[index]} alt={`Image ${index + 1}`} className="img1"/>
            <img src={imageUrls1[index]} alt={`Image ${index + 1}`} className={`overlay-image ${index % 2 === 0 ? 'even-index1' : 'odd-index1'}`} />
          </div>
          
        </div>
          
        ))}
        
      </div>
    
      
    </div>
    <div className="Contact">
           <div className="whatsapp-section1">
           <a href="https://api.whatsapp.com/send/?phone=9585345454&text=hi&type=phone_number&app_absent=0"><button>Get More Details</button></a>
           </div>
           </div>
        
           
        </div>

    </>
  );
};

export default UpcomingTrip;
