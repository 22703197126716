// Home.js
import React from 'react';
import { useState } from 'react';
import PopularTrips from '../components/PopularTrips';
import WhyChoose from '../components/WhyChoose';
import AncientSec from '../components/Ancient';
import UpcomingTrip from '../components/UpcomingTrips';
import Header from '../components/Header'
import Banner from '../components/Banner'
import Gallery from '../components/OurGallery.js';
import Review from '../components/Review.js';
import Contact from '../components/Contact.js';
import Footer from '../components/Footer.js';
import DreamTour from '../components/Tourdreams.js'
import Next1 from '../components/Test.js';
var Loader = require('react-loader') 
// import Header from '../components/Header';
// import Footer from '../components/Footer';

const Home = () => {
  
  return (
    <>
    
      {/* <Header />
      <Navbar />
      <Content />
      <Footer /> */}
      <Header />
      <Banner />
      <Next1 />
      <PopularTrips />
      <UpcomingTrip />
      <Gallery/>
      <WhyChoose/>
      <AncientSec />
      <DreamTour/>
      <Review />
      <Contact />
      <Footer />
   </>
  );
};

export default Home;
