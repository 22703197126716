import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import "../assets/css/Contactus.css";
var Loader = require('react-loader')

const ContactUs = () => {
    
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
  
    setFormData({
      name: "",
      email: "",
      message: ""
    });
  
    alert("Form submitted successfully!");
  };

  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
        setLoaded(true);
      }, 300);
  }, []);

 
  return (
    <>
    <Loader loaded={loaded}>
    <Header />
      <div>
        <div className="mess">

        <div className="section section-custom-13">
          <div className="containers">
            <div className="headline">
              <h1 className="name text-center">Contact Us</h1>
              <p className="headline_summary">
              Contact us anytime, anywhere. Our business WhatsApp enquire with FAQs and travel detail to ensure instant support for a seamless journey.
              </p>
            </div>
          </div>
        </div>
          <div className="con">
            <form className="contact-form" onSubmit={handleSubmit}>
              <h5 className="title"></h5>
              <p className="description"></p>
              <div>
                <input
                  type="text"
                  name="name"
                  className="form-control rounded border-white mb-3 form-input"
                  placeholder="Enter Your Name"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  className="form-control rounded border-white mb-3 form-input"
                  required
                  placeholder="Enter Your Email"
                />
              </div>
              <div>
                <textarea
                  id="message"
                  name="message"
                  className="form-control rounded border-white mb-3 form-text-area"
                  rows="5"
                  cols="30"
                  required
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="submit-button-wrapper">
                <input type="submit" />
              </div>
            </form>
          </div>
        </div>
        <div className="row address-1">
          <div className="col-lg-6 col-sm-12 col-md-6 pb-3">
            
            <h6>Shree Raghavendarar Travels</h6>
            <h6>221, New Scheme Road,</h6>
            <h6>Pappanaickenpalayam, </h6>
            <h6>Coimbatore, </h6>
            <h6>Tamil Nadu - 641037.</h6>
            <h6><a href="tel:+917708444333">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
                      </svg>
                </span> 77084 44333</a> &ensp;</h6>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-6 right">
            <h6><a href="mailto:srisrst2009@gmail.com">shreeraghavendarartravels.com</a></h6>
        
            <h6>94424 34343</h6>
            <h6>94425 45454</h6>
            <h6>94435 45454</h6>
            <h6>04222 245454</h6>
            <h6>04224 200400</h6>
          </div>
        </div>
        <div className="mapsection">
          <div className="map">
            <section className="g-map">
              <div className="map" id="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.3203896060727!2d76.97959040856873!3d11.014570854714815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859b4ad0b9cfd%3A0x927b461254a4da93!2sShree%20Raghavendarar%20Travels!5e0!3m2!1sen!2sin!4v1705561296190!5m2!1sen!2sin" width="100%" height="400"></iframe>
              </div>
            </section>
          </div>
          <div className="carousel"></div>
        </div>
      </div>
      <Footer />
      </Loader>
    </>
  );
};

export default ContactUs;
