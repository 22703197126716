import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/responsive1.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12 col-md-4">
            <div className="CompanyLogo">
              <img src="images/Logo.svg" className="footer-logo" alt=""/>
            </div>
            <p className="companyName">Shree Raghavendarar Travels</p>

            <p className="footer-des">
              We offer the best pilgrimage tours in India that assist you to top
              religious sites right from Pancha Jyothilingam Shiridi, Pancha
              Dwaraka Yatra, Kasi, Navabrindavan Yatra and Ahopilam Yatra. We
              promise a memorable experience steeped in spirituality and
              comfort.
            </p>
            <div className="row">
              <div className="col-1">
                <a href="https://www.facebook.com/shreeraghavendarartravel">
                  <img src="images/fb.png" className="footer-logo" alt=""/>
                </a>
              </div>

              <div className="col-1">
                <a href="https://api.whatsapp.com/send/?phone=9585345454&text=hi&type=phone_number&app_absent=0">
                  <img src="images/whatsapp.png" className="footer-logo" alt=""/>
                </a>
              </div>

              <div className="col-1">
                <a href="https://www.instagram.com/shreeraghavendarartravels/?fbclid=IwAR0WazmGV4lTrzyGBGOUeaj1Feprby-PTyE1fydkyIwXREJGEJHCXkobw7w">
                  <img src="images/insta.png" className="footer-logo" alt=""/>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 col-md-4 quick-links">
            <h2>
              <span class="background-text">Quick Links</span>
              <img src="images/round-img.png" alt=""/>
            </h2>
            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/" className="text-white link">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    Home
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/about-us" className="link text-white">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    About Us
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/list" className="link text-white">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    Upcoming Trips
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/" className="link text-white">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    Destinations
                  </Link>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/" className="link text-white">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    Gallery
                  </Link>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <p>
                  <Link to="/contactus" className="link text-white">
                    <span class="Arrow">
                      <img src="images/Arrow.png" alt=""/>
                    </span>{" "}
                    Contact
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 col-md-4 address">
            <h2>
              <span class="background-text">Contact</span>
              <img src="images/round-img.png" alt=""/>
            </h2>
            <p>
              <a href="tel:+917708444333">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                  </svg>
                </span>{" "}
                77084 44333
              </a>{" "}
              &ensp;<a href="tel:+919443545454">94435 45454</a>
            </p>

            <p>
              <a href="tel:+919442545454 ">
                <span>
                  <img src="images/phone.svg" alt=""/>
                </span>{" "}
                94425 45454{" "}
              </a>{" "}
              &ensp; <a href="tel:919442434343">94424 34343</a>
            </p>
            <p>
              &ensp;&ensp;<a href="tel:04222245454"> 04222 245454</a>
              &ensp;&ensp;<a href="tel:04224200400"> 04224 200400</a>
            </p>

            <div className="d-flex">
              <span>
                <img src="images/geo-alt-fill.svg" alt=""/>
              </span>
              <p className="pl-2">
                Shree Raghavendarar Travels 221, New Scheme Road,
                Pappanaickenpalayam, Coimbatore, Tamil Nadu - 641037
              </p>
            </div>
            <p className="mail">
              <a href="mailto:srisrst2009@gmail.com">
                <span>
                  <img src="images/mail.svg" alt=""/>
                </span>
                &ensp; srisrst2009@gmail.com
              </a>
            </p>
          </div>
        </div>

        <div className="row terms-condition">
          <div className="policy">
            <div className="copy-rights">
              <p className="">
                Ⓒ2023 Shree Raghavendarar Travels. Designed by{" "}
                <Link
                  to="https://www.bristoninnolabs.com/"
                  className="text-decoration-none text-white"
                >
                  Briston Innovation Labs Pvt Ltd.
                </Link>
              </p>
            </div>
            <div className="">
              <p>
                <Link to="/terms" className="nav-link text-white">
                  Terms and Conditions
                </Link>
              </p>
            </div>
            <div className="">
              <p>
                <Link to="/privacy" className="nav-link text-white">
                  Privacy Policy
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
