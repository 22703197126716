import "../assets/css/style.css";
// import "../assets/css/responsive.css"; 
import "../assets/css/responsive1.css"; 
import  line from "../assets/img/Vector.png";

import  Ancient from "../assets/img/Rare-Ancient.png";

import  Train from "../assets/img/Train-tour.png";
import  Food from "../assets/img/food.png";

const WhyChoose = () => {


  return (
    < >
        <div className="col-lg-12 text-center hello" >

          
          <div className="row">
           <div className="tour-dreams">
          <h1 className="normal-text1">
            Why Choose <span className='colGreen1'>Us ? <p className='LineSVG1 Small'> 
            <img src="images/vector.png" /> </p> </span>
          </h1>
        </div>
          </div>
          
          <div className="Choose-us container" >
            <div className="row">
               
               <div className="col-lg-3 col-md-6 col-sm-12" id="trip-grp">
                  <img className="tripImg" src={Ancient}/>
                  <div className="Trip-name" >
                    <h3>Proven Legacy (18 Years)</h3>
                    <p className="text-center">Trust us for 18 years of travel wisdom, ensuring your journey is seasoned with reliability, expertise, and timeless adventure.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12" id="trip-grp">
                  <img className="tripImg" src={Food}/>
                  <div className="Trip-name">
                    <h3>Tasty Food</h3>
                    <p>Explore diverse flavors curated to perfection, making each meal a celebration of global tastes – because great adventures should be delicious too.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12" id="trip-grp">
                  <img className="tripImg" src="images/contact.png"/>
                  <div className="Trip-name">
                    <h3>Reliable Customer Service(24/7)</h3>
                    <p>Contact us anytime, anywhere. Our business WhatsApp, equipped with FAQs and travel details, ensures instant support for a seamless journey.</p>
                  </div>
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12" id="trip-grp">
                  <img className="tripImg" src={Train}/>
                  <div className="Trip-name">
                    <h3>Personalized Experiences</h3>
                    <p>Our team tailors every journey to your unique preferences, ensuring that your adventure is as distinct as you are.</p>
                  </div>
               </div>
               
               
            </div>
          </div>
        </div>
      
      </>
  );
};

export default WhyChoose;
