

import "../assets/css/style.css"; // Import your custom CSS file
import "../assets/css/responsive.css"; 
import "../assets/css/responsive1.css"; 
import '../assets/css/Ancient.css'


const AncientSec = () => {
 

  return (
    <> 
      <div className="col-12 temple-back">
       <div className="Quotes container">
        <h1>Personalized Pilgrimages and Spiritual Trips</h1>
        <p className="Ques">Planning Personalized Pilgrimages and Spiritual Trips?</p>
        <p className="Para">Whether you seek serene retreats, sacred pilgrimages, or cultural immersions, we have the perfect customized experience waiting for you. Embark on a transformative journey with us and let your spiritual exploration begin. Contact us for more details.</p>
        {/* <p>Contact us for more details and let us help you create a personalized and enriching religious travel experience. Your spiritual adventure awaits!</p> */}
        <div className="Contact1">
        <div className="whatsapp-section2">
        <a href="tel:+919443545454"><button>Contact us</button></a>
        </div>
      </div>
       </div>
      </div>
    </>
  );
};

export default AncientSec;