import React from 'react';
import '../assets/css/Banner.css';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
      <div className="banner-container">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-pause="false">
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="6000">
              <img src="/images/k-2.jpeg" className="d-block w-100 d-md-none" alt="..." />
              <img src="/images/kashi6.jpg" className="d-block w-100 d-none d-md-block" alt="..." />
              <div className="carousel-caption">
                <h5>Kasi and Ayodhya</h5>
                <p>"Explore the spiritual ambiance of Kasi and the historical roots of Ayodhya with our curated packages, offering a firsthand experience of India's rich cultural tapestry and deep spiritual heritage."</p>
                <div className="ViewMoreButton1">
                  <Link to="/list"><button className="bottom-button2">Explore Date</button></Link>
                </div> 
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="6000">
              <img src="/images/k-3.jpg" className="d-block w-100 d-md-none" alt="..." />
              <img src="/images/ban-4.jpg" className="d-block w-100 d-none d-md-block" alt="..." />
              <div className="carousel-caption">
                <h5>Vaishnavi Yatra</h5>
                <p>"Experience the Vaishnavi Yatra to Vaishno Devi Temple in the Himalayas with our curated packages. Discover breathtaking landscapes and ancient traditions while fostering devotion and inner peace."</p>
                <div className="ViewMoreButton1">
                  <Link to="/list"><button className="bottom-button2">Explore Date</button></Link>
                </div> 
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="6000">
              <img src="/images/k-4.jpg" className="d-block w-100 d-md-none" alt="..." />
              <img src="/images/baner-2.3.jpg" className="d-block w-100 d-none d-md-block" alt="..." />
              <div className="carousel-caption">
                <h5>Sri lanka</h5>
                <p>"Discover Sri Lanka's diverse adventures – from pristine beaches to ancient temples and lush tea plantations – with our curated packages. Immerse yourself in warm hospitality and vibrant culture while exploring stunning landscapes and rich heritage."</p>
                <div className="ViewMoreButton1">
                  <Link to="/list"><button className="bottom-button2">Explore Date</button></Link>
                </div> 
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="6000">
              <img src="/images/k-5.jpg" className="d-block w-100 d-md-none" alt="..." />
              <img src="/images/baner-2.2.jpg" className="d-block w-100 d-none d-md-block" alt="..." />
              <div className="carousel-caption">
                <h5>Navabrindavan Yatra</h5>
                <p>"Experience the Navabrindavan Yatra in Karnataka, India, honoring nine saints, including Sri Raghavendra Swami. Seek blessings and immerse yourself in spirituality with our curated packages."</p>
                <div className="ViewMoreButton1">
                  <Link to="/list"><button className="bottom-button2">Explore Date</button></Link>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="menu px-4">
        <div className='container'>
          <div className="row text-md-center">
            <div className="col-3 w-25 pt-4">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" fill="#b3ef8e" viewBox="0 0 576 512"><path d="M288 0C422.4 0 512 35.2 512 80V96l0 32c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32l0 160c0 17.7-14.3 32-32 32v32c0 17.7-14.3 32-32 32H416c-17.7 0-32-14.3-32-32V448H192v32c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32l0-32c-17.7 0-32-14.3-32-32l0-160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h0V96h0V80C64 35.2 153.6 0 288 0zM128 160v96c0 17.7 14.3 32 32 32H272V128H160c-17.7 0-32 14.3-32 32zM304 288H416c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H304V288zM144 400a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm288 0a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM384 80c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16s7.2 16 16 16H368c8.8 0 16-7.2 16-16z"/></svg>
              <p className="para text-white">Personalized Experiences</p>
            </div>
            <div className="col-3 w-25 pt-4">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" fill="#b3ef8e" viewBox="0 0 512 512"><path d="M120 0c13.3 0 24 10.7 24 24v8h40V24c0-13.3 10.7-24 24-24s24 10.7 24 24v8h48V24c0-13.3 10.7-24 24-24s24 10.7 24 24v8h40V24c0-13.3 10.7-24 24-24s24 10.7 24 24v8V64v64c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32v96c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32H416V352H384V224H352V128H320v96h32V352h32V512H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H128V352h32V224h32V128H160v96H128V352H96V512H32c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32V256c0-17.7 14.3-32 32-32V160c0-17.7 14.3-32 32-32V64 32 24c0-13.3 10.7-24 24-24zM256 272c-17.7 0-32 14.3-32 32v48h64V304c0-17.7-14.3-32-32-32zm-32-80v32h64V192c0-17.7-14.3-32-32-32s-32 14.3-32 32z"/></svg>
              <p className="para text-white">18 Years Service</p>
            </div>
            <div className="col-3 w-25 pt-4">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" fill="#b3ef8e" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z"/></svg>
              <p className="para text-white">Customer Service (24/7)</p>
            </div>
            <div className="col-3 w-25 pt-4">
              <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" fill="#b3ef8e" viewBox="0 0 512 512"><path d="M0 192c0-35.3 28.7-64 64-64c.5 0 1.1 0 1.6 0C73 91.5 105.3 64 144 64c15 0 29 4.1 40.9 11.2C198.2 49.6 225.1 32 256 32s57.8 17.6 71.1 43.2C339 68.1 353 64 368 64c38.7 0 71 27.5 78.4 64c.5 0 1.1 0 1.6 0c35.3 0 64 28.7 64 64c0 11.7-3.1 22.6-8.6 32H8.6C3.1 214.6 0 203.7 0 192zm0 91.4C0 268.3 12.3 256 27.4 256H484.6c15.1 0 27.4 12.3 27.4 27.4c0 70.5-44.4 130.7-106.7 154.1L403.5 452c-2 16-15.6 28-31.8 28H140.2c-16.1 0-29.8-12-31.8-28l-1.8-14.4C44.4 414.1 0 353.9 0 283.4z"/></svg>
              <p className="para text-white">Tasty Food </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
