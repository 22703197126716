import React, { useState, useRef, useEffect } from 'react';
import "../assets/css/style.css"; 
import "../assets/css/responsive.css"; 
import "../assets/css/responsive1.css"; 
import background from "../assets/img/popular-bg.png";

import playIcon from "../public/images/play-circle-fill.svg"; 
import pauseIcon from "../public/images/pause-circle-fill.svg";

const PopularTrips = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting && isPlaying) {
        togglePlayPause();
      }
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isPlaying]);

  const containerStyle = {
    backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0)), url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover', 
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden', 
    position: 'relative',
  };

  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      <div className="col-12 text-center hello" style={containerStyle} ref={containerRef}>
        
        <div className="row">
          <div className="tour-dreams">
            <h1 className="normal-text1" >
              Our Recent <span className='colGreen1'> Trips <p className='LineSVG1'> 
              <img src="images/vector.png" /> </p> </span>
            </h1>
          </div>
        </div>

        <div className="flare">
          <video ref={videoRef} onClick={togglePlayPause}  muted loop >
            <source src="video/Spiritual-Tour.mp4" type="video/mp4"/>               
          </video>
          <div 
            className="play-pause-icon" 
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={togglePlayPause}
          >
            <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? "Pause" : "Play"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularTrips;
