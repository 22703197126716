import '../assets/css/Review.css';


const Review = () => {


  return (
    < >
        <div className="col-12 Customer-review">
         
          <div className="container" >
            <div className="row reviewSection">
               <div className="col-md-4">
                  <div className="img-review">
                  <img src="images/arun.png" />
                  <h6 className='personName'>Arun</h6>
                  </div>
                  <p className="review">Wow! 18 years of travel expertise truly shines through. From the moment I booked, everything was seamless. The personalized touch made my journey unforgettable. Highly recommend!</p>
               </div>
               
               
               <div className="col-md-4">
                  <div className="img-review">
                  <img src="images/dharun.png" />
                  <h6 className='personName'>Siva</h6>
                  </div>
                  <p className="review">The food! It's like they brought the world to my plate. A taste adventure in every meal. And their 24/7 support on WhatsApp? Superb. Can't wait for my next trip with them!</p>
               </div>

               <div className="col-md-4">
                  <div className="img-review">
                  <img src="images/varun.png" />
                  <h6 className='personName'>Vijaya Kumar</h6>
                  </div>
                  <p className="review">Reliable is an understatement. Had an issue at 3 AM, and they were there on WhatsApp, guiding me through with their FAQ bot. Top-notch service that saved my day. Five stars!</p>
               </div>
            </div>
          </div>

        </div>
      
      </>
  );
};

export default Review;
