import { Button } from "bootstrap";
import React, { useState, useEffect } from 'react';
import jsonData from '../Torudetails.json';

import ReactDOM  from "react-dom/client";
import {useNavigate} from 'react-router-dom';
import "../assets/css/next.css";
import img from "../assets/images/Vector (1).png";
import line from "../assets/img/Vector.png";

var Loader = require('react-loader')

const Next1 = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(false);
    const tours = jsonData.tours;
    // const sortedTours = tours.sort((a, b) => b.id - a.id);
    const limitedData = tours.slice(0, 12);
    limitedData.sort((a, b) => {
      return new Date(a.tourDetails.dates.startDate) - new Date(b.tourDetails.dates.startDate);
      })
   const limitedData1 = limitedData.slice(0, 4);
    if (limitedData) {
      setTimeout(() => {
          setData(limitedData1);
          setLoaded(true);
        }, 300);
    }
  }, []);

   const navigate = useNavigate();

  const detail=(data)=>{
    navigate('/detail',{state:{id:data}});
  }
  const list = () => {
    navigate("/list");
  };

  return (
    <div className="next">
    <Loader loaded={loaded}>
    <div className="container">
        <div className="col-12 col-sm-12 text-center">

        <div className="row">
           <div className="tour-dreams">
              <h1 className="normal-text1">
                Upcoming <span className='colGreen1'> Trips <p className='LineSVG1'> 
                <img src="images/vector.png" /> </p> </span>
              </h1>
           </div>
          </div>

<div className="container">
           <div className="row">
        {data.map((tour, index) => (
          <div key={index} className="col-lg-3 col-sm-12 col-md-4 tripImg-container">
          <div className="img-container2" onClick={()=>{detail(tour.id)}}>
          <div className="priceTag"><div><p className="Tag1">{formatAmount(tour.tourDetails.price.amount)}</p></div> <div><img className="Tag2" src={require(`../assets/img/${tour.tourDetails.transportType}.svg`)} ></img></div></div>
            <img src={tour.tourDetails.thumbnail1} alt={`Image ${index + 1}`} className="image1" />
            <div className="Image_Title"><img src="images/Vector (1).png"></img></div>
            <div className="Image_Title1"><p className="ImageText">{tour.tourDetails.displayName}</p></div>
            <div className="next-trips-duration1">
              <p>{tour.tourDetails.TimeDuration}</p>
            </div>
          </div>
          </div>
        ))}
        </div>
</div>
          </div>
          <div className="ViewMoreButton">
            <button
              className="bottom-button1"
              onClick={() => {
                list();
              }}
            >
              View More
            </button>
      </div>
        </div>
        </Loader>
    </div>
  );
};

function formatAmount(amount) {
  if (amount >= 10000000) {
    return "₹" + amount / 10000000 + "Cr";
  } else if (amount >= 100000) {
    return "₹" + amount / 100000 + "L";
  } else if (amount >= 1000) {
    return "₹" + amount / 1000 + "k";
  } else {
    return amount.toString();
  }
}


export default Next1;
